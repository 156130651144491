<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <div class="table-box">
        <el-row type="flex" justify="space-between">
          <el-row></el-row>
          <el-row class="right-btn m-bottom-5">
            <el-button @click="visible = true; action = 'add'" class="bg-gradient">创建渠道</el-button>
          </el-row>
        </el-row>
        <el-table ref="table" v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号" width="90">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="name" label="渠道名称"></el-table-column>
          <el-table-column label="分销类型">
            <template slot-scope="scope">{{ scope.row.type | filterType(that) }}</template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">{{ scope.row.status | filterStatus }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDownload(scope.row.id)">下载二维码</el-button>
              <el-button type="text" @click="handleStatistics">统计</el-button>
              <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" v-if="scope.row.status === '0'" @click="handleStatus(scope.row.id, '1')">启动</el-button>
              <el-button type="text" v-if="scope.row.status === '1'" @click="handleStatus(scope.row.id, '0')">
                <el-link type="danger">关闭</el-link>
              </el-button>
              <el-button type="text" @click="handleDel(scope.row.id)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
      </div>
    </div>
    <el-dialog title="创建渠道" :visible.sync="visible" :close-on-click-modal="false" width="550px" :before-close="handleClose">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="渠道名称" prop="name">
          <el-input v-model="ruleForm.name" class="width-280" placeholder="渠道名称"></el-input>
        </el-form-item>
        <el-form-item label="选择商品" required>
          <el-select v-model="ruleForm.type" class="width-280" placeholder="选择商品">
            <el-option v-for="(val, key) in { '1': '全部商品', '2': '指定商品', '3': '指定分类',
                      '4': '指定酒店' }" :key="key" :label="val" :value="key"></el-option>
          </el-select>
          <el-button v-if="ruleForm.type === '2'" @click="openSelectGoods" class="bg-gradient m-left-5">选择商品</el-button>
          <el-button v-else-if="ruleForm.type === '3'" @click="openSelectCategory" class="bg-gradient m-left-5">选择分类</el-button>
          <el-button v-else-if="ruleForm.type === '4'" @click="openSelectHotel" class="bg-gradient m-left-5">选择酒店</el-button>
        </el-form-item>
        <el-form-item label="分佣比例" prop="commissionRate" v-if="ruleForm.type === '1'">
          <el-input-number class="width-280" v-model="ruleForm.commissionRate" :precision="2" :step="1" :max="99.99" :min="0.00"></el-input-number>
          %
        </el-form-item>
        <el-form-item v-if="ruleForm.type !== '1'" label="" :prop="productMap.get(ruleForm.type)[0]">
          <el-table :data="ruleForm[productMap.get(ruleForm.type)[0]]" size="mini" border>
            <el-table-column label="序号" width="70">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column :label="`${ productMap.get(ruleForm.type)[1] }名称`" width="230">
              <template slot-scope="scope">{{ scope.row.name || scope.row.hotelName }}</template>
            </el-table-column>
            <el-table-column label="分佣比例%">
              <template slot-scope="scope">
                <el-input-number class="width-160" size="mini" v-model="scope.row.commissionRate" :precision="2" :step="1" :max="100" :min="0.00"></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSaveChannel">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择商品" :visible.sync="goodsVisible" width="550px">
      <el-table ref="goodsForm" :data="goodsData" :stripe="true" border fit
                @selection-change="changeSelectGoods">
        <el-table-column type="selection" width="80"></el-table-column>
        <el-table-column prop="name" label="商品名称"></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" :current-page="goodsPage" @current-change="changeGoodsCurrPage" :page-size="7" :total="goodsTotal"></el-pagination>
      <span slot="footer">
        <el-button @click="goodsVisible = false">取消</el-button>
        <el-button type="primary" @click="saveCheckGoods">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择酒店" :visible.sync="hotelVisible" width="550px">
      <el-table ref="hotelForm" :data="hotelData" :stripe="true" border fit
                @selection-change="changeSelectHotel">
        <el-table-column type="selection" width="80"></el-table-column>
        <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" :current-page="hotelPage" @current-change="changeHotelCurrPage" :page-size="7" :total="hotelTotal"></el-pagination>
      <span slot="footer">
        <el-button @click="hotelVisible = false">取消</el-button>
        <el-button type="primary" @click="saveCheckHotel">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择分类" :visible.sync="categoryVisible" width="550px">
      <el-tree ref="tree" :data="categoryTree" show-checkbox default-expand-all node-key="id"
               :props="{ children: 'children', label: 'name' }" :check-strictly="true"></el-tree>
      <span slot="footer">
         <el-button @click="categoryVisible = false">取消</el-button>
         <el-button type="primary" @click="saveCheckCategory">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="二维码" width="450px" :visible.sync="qrCodeVisible">
      <div class="qr-code-box">
        <el-image class="width-280" :src="qrCode"></el-image>
      </div>
      <span slot="footer">
        <el-button @click="qrCodeVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, saveChannel, delChannel, getHotelPage,
  generateQrCode } from "@/api/shop/distribution/distribution";
import { getProductPage } from '@/api/shop/conf/classify'
import { getList } from "@/api/shop/goods/category";
import { getDict } from "@/common/js/common";
import { mapState } from 'vuex';
import hotel from "@/views/AIOT/hotelManage/hotelList/hotel/hotel";
export default {
  name: 'channelDistribution',
  data() {
    return {
      crumbs: new Map([
          ['特惠商城'], ['分销中心'], ['渠道分销']
      ]),
      that: this,
      loading: false,
      tableData: [],
      limit: 1,
      page: 1,
      total: 0,
      visible: false,
      ruleForm: {
        id: '',
        name: '',
        type: '1',
        commissionRate: 0,
        productInfoList: [],
        productCategoryList: [],
        hotelList: []
      },
      rules: {
        name: [{ required: true, message: '渠道名称不能为空！' }],
        commissionRate: [{ required: true, message: '分佣比例不能为空！' }],
        productInfoList: [{ required: true, message: '指定商品不能为空！' }],
        productCategoryList: [{ required: true, message: '指定分类不能为空！' }],
        hotelList: [{ required: true, message: '指定酒店不能为空！' }]
      },
      productMap: new Map([
        ['2', ['productInfoList', '商品']],
        ['3', ['productCategoryList', '分类']],
        ['4', ['hotelList', '酒店']]
      ]),
      // 存储未保存时当前选中的项
      selectedData: {
        hotelList: new Map(),
        productInfoList: new Map(),
      },
      initForm: {},
      goodsVisible: false,
      goodsData: [],
      goodsTotal: 0,
      goodsPage: 1,
      categoryVisible: false,
      categoryTree: [],
      hotelVisible: false,
      hotelData: [],
      hotelPage: 1,
      hotelTotal: 0,
      action: 'add',
      // 会话框是否一次打开
      isFirstOpen: new Map([
          ['hotel', true], ['goods', true]
      ]),
      qrCodeVisible: false,
      qrCode: ''
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1;
    this.initForm = { ...this.ruleForm };
    getDict('distribution-type')
    this.getChannelList();
  },
  methods: {
    // 获取渠道列表
    getChannelList() {
      const params = {
        hotelId: this.hotelInfo.id,
        companyId: this.hotelInfo.storeId,
        limit: this.limit,
        page: this.page
      };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(params).then(({ success, records, total }) => {
        if (!success) return;
        this.total = total;
        this.tableData = records;
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num;
      this.getChannelList()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num;
      this.getChannelList()
    },
    // 打开选择商品会话框
    openSelectGoods() {
      this.goodsVisible = true;
      if (this.isFirstOpen.get('goods')) {
        this.isFirstOpen.set('goods', false)
        this.getProductList();
      }
    },
    // 获取商品列表
    getProductList() {
      const query = `?limit=7&page=${ this.goodsPage }`
      getProductPage(query).then(({ success, records, total }) => {
        if (!success) return;
        this.goodsTotal = total
        this.goodsData = records.map(i => {
          return { id: i.id, name: i.name, commissionRate: 0 }
        })
        const productInfoList = this.selectedData.productInfoList;
        // 编辑时，把已选中的项赋值到对应的分页
        if (this.action === 'edit' && !productInfoList.get(this.goodsPage)) {
          const arr = [];
          const editData = productInfoList.get('edit');
          if (editData) {
            this.goodsData.forEach(i => {
              const index = editData.findIndex(r => r.id === i.id)
              if (index !== -1) arr.push(...editData.splice(index, 1))
            })
            productInfoList.set(this.goodsPage, arr)
          }
        }
        // 每次获取数据后，对选中项进行重新勾选
        const currPageData = productInfoList.get(this.goodsPage);
        currPageData && currPageData.forEach(i => {
          this.$nextTick(() => {
            this.$refs.goodsForm.toggleRowSelection(this.goodsData.find(r => r.id === i.id))
          })
        })
      })
    },
    // 改变商品当前页
    changeGoodsCurrPage(num) {
      this.goodsPage = num;
      this.getProductList()
    },
    // 处理选中商品
    changeSelectGoods(val) {
      this.selectedData.productInfoList.set(this.goodsPage, val)
    },
    // 保存选中商品
    saveCheckGoods() {
      this.goodsVisible = false;
      const goodsList = [];
      for (const v of this.selectedData.productInfoList) goodsList.push(v[1]);
      goodsList.flat().forEach(i => {
        const hitRow = this.ruleForm.productInfoList.find(r => i.id === r.id)
        hitRow && (i.commissionRate = hitRow.commissionRate)
      })
      this.ruleForm.productInfoList = goodsList.flat()
    },
    // 打开选择分类会话框
    openSelectCategory() {
      this.categoryVisible = true;
      this.categoryTree.length === 0 && this.getCategorise(() => {
        if (this.action === 'edit') {
          this.$refs.tree.setCheckedKeys([...this.ruleForm.productCategoryList.map(i => i.id)])
        }
      })
    },
    // 获取商品分类
    getCategorise(cb) {
      getList({}).then(({ success, records }) => {
        if (success) {
          this.categoryTree = this.insertAttribute(records)
          cb && cb()
        }
      })
    },
    // 禁选一二级分类
    insertAttribute(categoryTree) {
      categoryTree.forEach(item => {
        if (item.level !== 3) item.disabled = true;
        if (item.hasOwnProperty('children')) this.insertAttribute(item.children)
      })
      return categoryTree
    },
    // 确定保存分类
    saveCheckCategory() {
      this.categoryVisible = false
      this.ruleForm.productCategoryList = this.$refs.tree.getCheckedNodes().map(i => {
        return { id: i.id, name: i.name, commissionRate: 0 }
      })
    },
    // 打开酒店会话框
    openSelectHotel() {
      this.hotelVisible = true
      if (this.isFirstOpen.get('hotel')) {
        this.isFirstOpen.set('hotel', false)
        this.getHotelList()
      }
    },
    // 获取酒店列表
    getHotelList() {
      const query = `?limit=7&page=${ this.hotelPage }`
      getHotelPage(query).then(({ success, records, total }) => {
        if (success) {
          this.hotelTotal = total
          this.hotelData = records.map(i => {
            return { id: i.id, hotelName: i.hotelName, commissionRate: 0 }
          })
          // 编辑时，把已选中的项赋值到对应的分页
          if (this.action === 'edit' && !this.selectedData.hotelList.get(this.hotelPage)) {
            const arr = [];
            const editData = this.selectedData.hotelList.get('edit');
            if (editData) {
              this.hotelData.forEach(i => {
                const index = editData.findIndex(r => r.id === i.id);
                if (index !== -1) arr.push(...editData.splice(index, 1))
              })
              this.selectedData.hotelList.set(this.hotelPage, arr)
            }
          }
          // 每次获取数据后，对选中项进行重新勾选
          const currPageData = this.selectedData.hotelList.get(this.hotelPage);
          currPageData && currPageData.forEach(i => {
            this.$nextTick(() => {
              this.$refs.hotelForm.toggleRowSelection(this.hotelData.find(r => r.id === i.id))
            })
          })
        }
      })
    },
    // 改变酒店当前页
    changeHotelCurrPage(num) {
      this.hotelPage = num
      this.getHotelList()
    },
    // 处理选中酒店
    changeSelectHotel(val) {
      this.selectedData.hotelList.set(this.hotelPage, val);
    },
    // 保存选中酒店
    saveCheckHotel() {
      this.hotelVisible = false;
      const hotelList = [];
      for (const v of this.selectedData.hotelList) hotelList.push(v[1])
      hotelList.flat().forEach(i => {
        const hitRow = this.ruleForm.hotelList.find(r => i.id === r.id);
        if (hitRow) i.commissionRate = hitRow.commissionRate
      })
      this.ruleForm.hotelList = hotelList.flat();
    },
    // 处理编辑
    handleEdit(row) {
      this.action = 'edit'
      this.visible = true
      for (const k in this.ruleForm) k in row && (this.ruleForm[k] = row[k])
      if (row.distributionItemList) {
        if (row.type === '1') {
          this.ruleForm.commissionRate = row.distributionItemList[0].commissionRate;
        } else {
          // 取出当前操作的对应字段名
          const fieldName = this.productMap.get(this.ruleForm.type)[0];
          this.ruleForm[fieldName] = row.distributionItemList.map(i => {
            return {
              id: i.srcHotelId || i.productId || i.categoryId,
              [this.ruleForm.type === '4' ? 'hotelName' : 'name']: i.name,
              commissionRate: i.commissionRate
            }
          })
          // 将编辑时默认选中的项放到临时存储对象'selectedData', 以便在打开会话框时可以默认勾选
          // 因为不知道当前项所在的分页数，临时存储到edit
          if (this.ruleForm.type === '2' || this.ruleForm.type === '4') {
            this.selectedData[fieldName].set('edit', [ ...this.ruleForm[fieldName] ])
          }
        }
      }
    },
    // 保存分销渠道
    handleSaveChannel() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const params = {
          ...this.ruleForm,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        }
        saveChannel(params, this.action).then(({ success }) => {
          if (!success) return;
          this.handleClose();
          this.getChannelList();
          this.$message({ message: '添加渠道成功！', type: 'success' })
        })
      })
    },
    // 启动/关闭
    handleStatus(id, status) {
      const str = status === '1' ? '启动' : '关闭';
      this.$confirm(`确定${str}当前分销渠道？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { id, status };
        saveChannel(params, 'edit').then(({ success }) => {
          if (!success) return;
          this.getChannelList();
          this.$message({ message: `${str}成功！`, type: 'success' })
        })
      })
    },
    // 删除分销渠道
    handleDel(id) {
      this.$confirm('确定删除当前分销渠道？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delChannel(`/${ id }`).then(({ success })=> {
          if (!success) return;
          this.getChannelList()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      })
    },
    // 关闭渠道会话框
    handleClose() {
      this.visible = false;
      this.ruleForm = { ...this.initForm };
      this.hotelPage = 1
      this.goodsPage = 1
      this.isFirstOpen.set('hotel', true)
      this.isFirstOpen.set('goods', true)
      for (const k in this.selectedData) this.selectedData[k].clear();
    },
    // 下载二维码
    handleDownload(id) {
      generateQrCode({ id }).then(({ success, records }) => {
        if (!success) return;
        if (records.qrCode) {
          this.qrCodeVisible = true;
          this.qrCode = 'data:image/png;base64,' + records.qrCode
        } else this.$message({ message: '缺少二维码地址！', type: 'error' })
      })
    },
    // 统计
    handleStatistics() {
      this.$router.push({'path': '/channel_distribution/statistics'})
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && oldVal.id !== newVal.id && this.getChannelList()
    }
  },
  filters: {
    filterType(val, that) {
      if (val && that.dictData['distribution-type']) return that.dictData['distribution-type'][val] || val
    },
    filterStatus(val) {
      const obj = { '1': '启动', '0': '关闭' };
      return obj[val]
    }
  }
}
</script>
<style scoped lang="scss">
.cont {
  ::v-deep .el-dialog__body{ padding-left: 40px }
  .qr-code-box{ text-align: center }
}
</style>
